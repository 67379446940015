

module.exports = {

  "about-us": "អំពីយើង",
  "contact-us": "ទំនាក់ទំនងយើង",
  "copyright": "Gogo React © 2018 All Rights Reserved.",
  "login": "ចូល",
  "sign-up": "ចុះឈ្មោះ",
  "sign-in": "ចូល",
};
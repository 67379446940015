// background
export const BACKGROUND = require("../assets/images/background/background.svg");
export const PEOPLES_EXPLAINING = require("../assets/images/background/peoples-explaining.svg");
export const PEOPLES_WORKING = require("../assets/images/background/peoples-working.svg");

// logo
export const LOGO = require("../assets/images/logo/smart_start_logo.png");

// utility
export const ENVELOPE = require("../assets/images/utility/envelope.svg");
export const FOLDER_RECTANGLE_CHECK = require("../assets/images/utility/folder-rectangle-check.svg");
export const FOLDER_RECTANGLE = require("../assets/images/utility/folder-rectangle.svg");
export const FOLDER = require("../assets/images/utility/folder.svg");

// canvas
export const IDEA_AND_GENERATION = require("../assets/images/canvas/idea-and-generation.svg");
export const PEOPLES_AND_ROLE_ICON = require("../assets/images/canvas/people-and-role.svg");
export const VALUE_ICON = require("../assets/images/canvas/value.svg");
export const PURPOSE_ICON = require("../assets/images/canvas/purpose.svg");
export const COMMON_GOAL_ICON = require("../assets/images/canvas/common-goal.svg");
export const PERSONAL_GOAL_ICON = require("../assets/images/canvas/personal-and-goal.svg");
export const RULE_AND_ACTIVITY_ICON = require("../assets/images/canvas/rule-and-activity.svg");
export const STRENGTH_AND_ASSET_ICON = require("../assets/images/canvas/strength-and-asset.svg");
export const NEED_AND_EXPECTATION_ICON = require("../assets/images/canvas/need-and-expectation.svg");

// About Us
export const CBRD = require("../assets/images/about-us/CBRD-LOGO.png");
export const IMPACT_HUB = require("../assets/images/about-us/Impact-Hub.png");
export const EDEMY = require("../assets/images/about-us/Learning-Platform-Partner-logo.png");
export const RULE_CAMBODIA = require("../assets/images/about-us/Logo_of_Rule_Cambodia.png");
export const MINISTRY_OF_EDUCATION = require("../assets/images/about-us/Ministry-of-Education.png");
export const MPTC = require("../assets/images/about-us/MPTC.jpg");
export const NATIONAL_UNIVERSITY_OF_CAMBODIA = require("../assets/images/about-us/National_University_of_Cambodia_logo.png");
export const NIPTICT = require("../assets/images/about-us/NIPTICT.png");
export const SMART_AXIATA = require("../assets/images/about-us/Smart-Axiata.png");
export const UP = require("../assets/images/about-us/UP.jpg");


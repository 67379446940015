// Libs
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Label } from "reactstrap";
import { useSelector } from "react-redux";
import _ from "lodash";

// Compoenent
import Title from "@/components/title";
import ClassBox from "../../components/class-box";

import { selectSchoolsByTeacher } from "@/stores/selector";
import { CLASS } from "@/constants/routes";
import { PEOPLES_WORKING } from "@/constants/images";

function SchoolList() {
  const { teacher } = useSelector(state => state.teacher);
  const selectorSchool = useSelector(selectSchoolsByTeacher);

  let name = _.isEmpty(teacher) ? "" : teacher.name;

  return (
    <Container fluid>
      <Row className="h-100">
        {!selectorSchool.length && (
          <Col xs="12" className="py-3 m-auto text-center">
            <Title title={`Welcome ${name}!`} className="text-primary" />
            <img width={350} height={350} src={PEOPLES_WORKING} />
            <Label className="d-block text-muted">
              You haven’t been assigned a school yet. Please contact your Dean
              or Impact Hub Phnom Penh for your class assignments.
            </Label>
          </Col>
        )}
        {selectorSchool.length > 0 && (
          <Col xs="12" className="pt-3 m-auto text-center bg-primary-fade">
            <Title title={`Welcome ${name}!`} className="text-primary" />
            <Title title={"Please choose school."} tag="h6"/>
            <Row className="justify-content-center">
              {selectorSchool.map((value, key) => {
                const { name, short_name, id } = value;
                return (
                  <Col key={key} xs="12" md="3" className="pb-3">
                    <Link to={`${CLASS}?school_id=${id}`}>
                      <ClassBox title={name} code={short_name} id={id} />
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default SchoolList;

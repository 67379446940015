/* eslint-disable indent */
import _ from "lodash";
import {
  CANVAS_SET_CANVASES_REQUEST,
  CANVAS_SET_CANVASES_SUCCESS,
  CANVAS_SET_CANVASES_ERROR,
  CANVAS_SET_CANVAS,
  CANVAS_SET_CANVASES,
  CANVAS_SET_CANVAS_BY_ID
} from "../actions/actionType";

const INIT_STATE = {
  canvases: [],
  canvas: {},
  loading: false,
  error: ""
};

const findById = (state, action) => {
  const found = _.find(state.canvases, { id: action.payload.canvasId });
  return { ...state, canvas: found ? found : {} };
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CANVAS_SET_CANVASES_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case CANVAS_SET_CANVASES_SUCCESS:
      return {
        ...state,
        loading: false,
        user: ""
      };
    case CANVAS_SET_CANVASES_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case CANVAS_SET_CANVASES:
      return { ...state, canvases: action.payload.canvases };
    case CANVAS_SET_CANVAS:
      return { ...state, canvas: action.payload.canvas };
    case CANVAS_SET_CANVAS_BY_ID:
      return findById(state, action);
    default:
      return { ...state };
  }
};

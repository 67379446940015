// Libs
import React, { Component, Fragment } from "react";
import { BrowserRouter, Router } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Components
import RouteComponent from "../routes";

// Helper
import { history } from "@/shared/utils/history";
// import languages from "@/languages";
import AppLocale from "@/lang";

class App extends Component {
  render() {
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale];
    return (
      <BrowserRouter>
        <Fragment>
          <Router history={history}>
            <IntlProvider
              locale={currentAppLocale.locale}
              messages={currentAppLocale.messages}
            >
              <RouteComponent />
            </IntlProvider>
          </Router>
        </Fragment>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  locale: PropTypes.string
};

const mapStateToProps = state => {
  return {
    locale: state.locale.locale
  };
};

export default connect(mapStateToProps)(App);

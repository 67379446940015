/* eslint-disable indent */
import {
  SESSION_LOG_SET_SESSIONS_REQUEST,
  SESSION_LOG_SET_SESSIONS_SUCCESS,
  SESSION_LOG_SET_SESSIONS_ERROR,
  SESSION_LOG_SET_SESSIONS,
  SESSION_LOG_SET_SESSION
} from "../actions/actionType";

const INIT_STATE = {
  sessionLogs: [],
  sessionLog: {},
  loading: false,
  error: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SESSION_LOG_SET_SESSIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case SESSION_LOG_SET_SESSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: ""
      };
    case SESSION_LOG_SET_SESSIONS_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case SESSION_LOG_SET_SESSIONS:
      return { ...state, sessionLogs: action.payload.sessionLogs };
    case SESSION_LOG_SET_SESSION:
      return { ...state, sessionLog: action.payload.sessionLog };
    default:
      return { ...state };
  }
};

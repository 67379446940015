import React, { useRef, useEffect, useCallback, useState } from "react";

import {
  ExcelExport,
  ExcelExportColumn
} from "@progress/kendo-react-excel-export";
import { process } from "@progress/kendo-data-query";
const products = [
  {
    ProductID: 1,
    ProductName: "Chai"
  },
  {
    ProductID: 2,
    ProductName: "Chang"
  }
];

const header = [
  { field: "ProductID", title: "Product ID", locked: true, width: 200 },
  { field: "ProductName", title: "Product Name", locked: true, width: 200 }
];

function Excel() {
  let [childRef, setChildRef] = useState(null);

  const exportData = useCallback(() => {
    childRef.save();
  }, [childRef]);

  return (
    <div>
      <button className="k-button" onClick={() => exportData()}>
        Export to Excel
      </button>
      <DownloadExcel
        dataSources={products}
        header={header}
        setChildRef={setChildRef}
      />
    </div>
  );
}

/*eslint-disable react/prop-types*/
function DownloadExcel({ dataSources, header, setChildRef }) {
  const data = process(dataSources, {}).data;
  let _exporter = useRef();

  useEffect(() => {
    setChildRef(_exporter);
  }, [setChildRef, _exporter]);

  return (
    <div>
      <ExcelExport
        data={data}
        fileName="Products.xlsx"
        ref={exporter => {
          _exporter = exporter;
        }}
      >
        {header.map(item => {
          return <ExcelExportColumn key={item.field} {...item} />;
        })}
      </ExcelExport>
    </div>
  );
}

export default Excel;

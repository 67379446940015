// import logger from "redux-logger";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import createSagaMiddleware from "redux-saga";
import sagas from "./saga";

const configureStore = initailState => {
  const middlewares = [];
  const sagaMiddleware = createSagaMiddleware();
  if (process.env.NODE_ENV !== "production") {
    // middlewares.push(logger);
  }

  middlewares.push(thunk);
  middlewares.push(sagaMiddleware);

  const store = createStore(
    rootReducer,
    initailState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(sagas);

  return store;
};

export default configureStore;

import React, { Component } from "react";
import PropTypes from "prop-types";
import IntlMessages from "@/helpers/IntlMessages";

class Title extends Component {
  render() {
    const { tag: Tag, className, title, isLocale } = this.props;
    let tmpTitle = isLocale ? <IntlMessages id={title} /> : title;
    return <Tag className={className}>{tmpTitle}</Tag>;
  }
}

Title.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  isLocale: PropTypes.bool
};

Title.defaultProps = {
  tag: "h5",
  isLocale: false
};

export default Title;

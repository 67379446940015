import {
  CANVAS_GET_CANVASES,
  CANVAS_SET_CANVASES_SUCCESS,
  CANVAS_SET_CANVASES_REQUEST,
  CANVAS_SET_CANVASES_ERROR,
  CANVAS_SET_CANVASES,
  CANVAS_SET_CANVAS_BY_ID
} from "./actionType";
import { firestoreRef } from "@/constants/firebase";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

export const setCanvases = canvases => ({
  type: CANVAS_SET_CANVASES,
  payload: { canvases }
});

export const setCanvasById = canvasId => ({
  type: CANVAS_SET_CANVAS_BY_ID,
  payload: { canvasId }
});

export const getCanvases = () => ({
  type: CANVAS_GET_CANVASES
});

export const setCanvasesSuccess = () => ({
  type: CANVAS_SET_CANVASES_SUCCESS
});

export const setCanvasesRequest = () => ({
  type: CANVAS_SET_CANVASES_REQUEST
});

export const setCanvasesError = message => ({
  type: CANVAS_SET_CANVASES_ERROR,
  payload: { message }
});

const getCanvasesAsync = async () =>
  await firestoreRef
    .collection("canvases")
    .get()
    .then(snapshots => {
      let data = [];
      snapshots.forEach(doc => {
        data.push({
          ...doc.data(),
          id: doc.id
        });
      });
      return data;
    });

function* fetchCanvases() {
  try {
    yield put(setCanvasesRequest());
    const canvases = yield call(getCanvasesAsync);
    yield put(setCanvases(canvases));
    yield put(setCanvasesSuccess());
  } catch (error) {
    yield put(setCanvasesError(error.message));
  }
}

export function* watchGetCanvases() {
  yield takeEvery(CANVAS_GET_CANVASES, fetchCanvases);
}

export default function* rootSaga() {
  yield all([fork(watchGetCanvases)]);
}

import { createSelector } from "reselect";
import _ from "lodash";
const classes = state => state.class.classes;
const teacher = state => state.teacher.teacher;
const school = state => state.school.school;

export const selectClasses = createSelector(
  classes,
  teacher,
  school,
  (classes, teacher, school) => {
    return _.filter(classes, cls => {
      return teacher.id === cls.teacher_id && cls.school_id === school.id;
    });
  }
);

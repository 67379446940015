import {
  SESSION_SET_SESSIONS,
  SESSION_GET_SESSIONS,
  SESSION_SET_SESSIONS_SUCCESS,
  SESSION_SET_SESSIONS_REQUEST,
  SESSION_SET_SESSIONS_ERROR,
} from './actionType';
import { firestoreRef } from '@/constants/firebase';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

export const setSessions = (sessions) => ({
  type: SESSION_SET_SESSIONS,
  payload: { sessions },
});

export const getSessions = () => ({
  type: SESSION_GET_SESSIONS,
});

export const setSessionsSuccess = () => ({
  type: SESSION_SET_SESSIONS_SUCCESS,
});

export const setSessionsRequest = () => ({
  type: SESSION_SET_SESSIONS_REQUEST,
});

export const setSessionsError = (message) => ({
  type: SESSION_SET_SESSIONS_ERROR,
  payload: { message },
});

const getSessionsAsync = async () =>
  await firestoreRef
    .collection('sessions')
    .orderBy('rank', 'asc')
    .get()
    .then((snapshots) => {
      let data = [];
      snapshots.forEach((doc) => {
        data.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return data;
    });

function* fetchSessions() {
  try {
    yield put(setSessionsRequest());
    const sessions = yield call(getSessionsAsync);
    yield put(setSessions(sessions));
  } catch (error) {
    yield put(setSessionsError(error.message));
  }
}

export function* watchGetSessions() {
  yield takeEvery(SESSION_GET_SESSIONS, fetchSessions);
}

export default function* rootSaga() {
  yield all([fork(watchGetSessions)]);
}

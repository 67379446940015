import {
  SCHOOL_SET_SCHOOLS,
  SCHOOL_GET_SCHOOL,
  SCHOOL_GET_SCHOOLS,
  SCHOOL_SET_SUCCESS,
  SCHOOL_SET_REQUEST,
  SCHOOL_SET_ERROR,
  SCHOOL_SET_SCHOOL,
  SCHOOL_SET_BY_ID
} from "./actionType";
import { firestoreRef } from "@/constants/firebase";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

export const setSchools = schools => ({
  type: SCHOOL_SET_SCHOOLS,
  payload: { schools }
});

export const setSchool = school => ({
  type: SCHOOL_SET_SCHOOL,
  payload: { school }
});

export const setSchoolById = id => ({
  type: SCHOOL_SET_BY_ID,
  payload: { id }
});

export const getSchools = () => ({
  type: SCHOOL_GET_SCHOOLS
});

export const getSchool = () => ({
  type: SCHOOL_GET_SCHOOL
});

export const setSchoolSuccess = () => ({
  type: SCHOOL_SET_SUCCESS
});

export const setSchoolRequest = () => ({
  type: SCHOOL_SET_REQUEST
});

export const setSchoolError = message => ({
  type: SCHOOL_SET_ERROR,
  payload: { message }
});

const getSchoolsAsync = async () =>
  await firestoreRef
    .collection("schools")
    .get()
    .then(snapshots => {
      let data = [];
      snapshots.forEach(doc => {
        data.push({
          ...doc.data(),
          id: doc.id
        });
      });
      return data;
    });

const getSchoolAsync = async id => {
  return await firestoreRef
    .collection("schools")
    .doc(id)
    .get()
    .then(doc => {
      let data = { ...doc.data(), id: doc.id };
      return data;
    });
};

function* fetchSchools() {
  try {
    yield put(setSchoolRequest());
    const schools = yield call(getSchoolsAsync);
    yield put(setSchools(schools));
    yield put(setSchoolSuccess());
  } catch (error) {
    yield put(setSchoolError(error.message));
  }
}

function* fetchSchool({ payload }) {
  try {
    const { id } = payload;
    yield put(setSchoolRequest());
    const school = yield call(getSchoolAsync, id);
    yield put(setSchool(school));
    yield put(setSchoolSuccess());
  } catch (error) {
    yield put(setSchoolError(error.message));
  }
}

export function* watchGetSchools() {
  yield takeEvery(SCHOOL_GET_SCHOOLS, fetchSchools);
}

export function* watchGetSchool() {
  yield takeEvery(SCHOOL_GET_SCHOOL, fetchSchool);
}

export default function* rootSaga() {
  yield all([fork(watchGetSchools), fork(watchGetSchool)]);
}

/* eslint-disable indent */
import {
  QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_REQUEST,
  QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_SUCCESS,
  QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_ERROR,
  QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS,
  QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOG
} from "../actions/actionType";

const INIT_STATE = {
  quizResultLogs: [],
  quizResultLog: {},
  loading: false,
  error: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: ""
      };
    case QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS:
      return { ...state, quizResultLogs: action.payload.quizResultLogs };
    case QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOG:
      return { ...state, quizResultLog: action.payload.quizResultLog };
    default:
      return { ...state };
  }
};

import {
  CANVAS_TEAM_SUBMIT_GET_CANVAS_TEAMS,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_SUCCESS,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_REQUEST,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_ERROR,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS,
  CANVAS_TEAM_SUBMIT_UPDATE_CANVAS_TEAM,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAM,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAM_BY_ID
} from "./actionType";
import { firestoreRef } from "@/constants/firebase";
import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import _ from "lodash";

export const setCanvasTeamSubmits = canvasTeamSubmits => ({
  type: CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS,
  payload: { canvasTeamSubmits }
});

export const setCanvasTeamSubmit = canvasTeamSubmit => ({
  type: CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAM,
  payload: { canvasTeamSubmit }
});

export const setCanvasTeamSubmitById = canvasTeamSubmitId => ({
  type: CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAM_BY_ID,
  payload: { canvasTeamSubmitId }
});

export const getCanvasTeamSubmits = () => ({
  type: CANVAS_TEAM_SUBMIT_GET_CANVAS_TEAMS
});

export const updateCanvasTeamSubmit = data => ({
  type: CANVAS_TEAM_SUBMIT_UPDATE_CANVAS_TEAM,
  payload: { data }
});

export const setCanvasTeamSubmitsSuccess = () => ({
  type: CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_SUCCESS
});

export const setCanvasTeamSubmitsRequest = () => ({
  type: CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_REQUEST
});

export const setCanvasTeamSubmitsError = message => ({
  type: CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_ERROR,
  payload: { message }
});

const getCanvasTeamSubmitsAsync = async obj => {
  const { cls } = obj;
  return await firestoreRef
    .collection("canvas_team_submits")
    .where("class_id", "==", cls.id)
    .get()
    .then(snapshots => {
      let data = [];
      snapshots.forEach(doc => {
        data.push({
          ...doc.data(),
          id: doc.id
        });
      });
      return data;
    });
};

const updateCanvasTeamSubmitAsync = async obj => {
  const { cls, team, data: value, teacher, canvas } = obj;
  return await firestoreRef
    .collection("canvas_team_submits")
    .where("class_id", "==", cls.id)
    .where("canvas_id", "==", canvas.id)
    .where("team_id", "==", team.id)
    .get()
    .then(snapshots => {
      if (_.isEqual(snapshots.size, 0)) return null;
      snapshots.forEach(doc => {
        firestoreRef
          .collection("canvas_team_submits")
          .doc(doc.id)
          .update({
            comment: value.comment,
            status: value.status,
            teacher_id: teacher.id
          });
      });

      return true;
    });
};

function* fetchCanvasSubmits() {
  try {
    const { cls } = yield select(state => state.class);
    yield put(setCanvasTeamSubmitsRequest());
    const canvasTeamSubmits = yield call(getCanvasTeamSubmitsAsync, { cls });
    yield put(setCanvasTeamSubmits(canvasTeamSubmits));
    yield put(setCanvasTeamSubmitsSuccess());
  } catch (error) {
    yield put(setCanvasTeamSubmitsError(error.message));
  }
}

function* performUpdateCanvasTeamSubmit({ payload }) {
  try {
    const { data } = payload;
    const { cls } = yield select(state => state.class);
    const { team } = yield select(state => state.team);
    const { teacher } = yield select(state => state.teacher);
    const { canvas } = yield select(state => state.canvas);

    yield put(setCanvasTeamSubmitsRequest());
    yield call(updateCanvasTeamSubmitAsync, {
      cls,
      canvas,
      data,
      team,
      teacher
    });
    yield put(setCanvasTeamSubmitsSuccess());
  } catch (error) {
    yield put(setCanvasTeamSubmitsError(error.message));
  }
}

export function* watchGetCanvasTeamSubmits() {
  yield takeEvery(CANVAS_TEAM_SUBMIT_GET_CANVAS_TEAMS, fetchCanvasSubmits);
}

export function* watchPerformUpdateCanvasTeamSubmit() {
  yield takeEvery(
    CANVAS_TEAM_SUBMIT_UPDATE_CANVAS_TEAM,
    performUpdateCanvasTeamSubmit
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetCanvasTeamSubmits),
    fork(watchPerformUpdateCanvasTeamSubmit)
  ]);
}

import { createSelector } from "reselect";
import _ from "lodash";
import { formatDateFirestore } from "@/helpers";
import { STATUS_DEFAULT } from "@/constants/constants";
const teams = state => state.team.teams;
const team = state => state.team.team;
const teamMembers = state => state.teamMember.teamMembers;
const users = state => state.user.users;
const canvasTeamSubmits = state => state.canvasTeamSubmit.canvasTeamSubmits;
const canvas = state => state.canvas.canvas;
const canvases = state => state.canvas.canvases;

export const selectTeamsByClass = createSelector(
  teams,
  teamMembers,
  users,
  (teams, teamMembers, users) =>
    _.map(teams, team => {
      let members = _.filter(teamMembers, teamMember => {
        return teamMember.team_id === team.id;
      });

      let tmpMembers = _.map(members, member => {
        let found = _.find(users, { id: member.user_id });
        return !_.isNil(found) ? found : {};
      });

      return {
        ...team,
        members: tmpMembers
      };
    })
);

export const selectCanvasTeamSubmitsByCanvas = createSelector(
  canvasTeamSubmits,
  canvas,
  (canvasTeamSubmits, canvas) =>
    _.filter(canvasTeamSubmits, canvasTeamSubmit => {
      return canvasTeamSubmit.canvas_id === canvas.id;
    })
);

export const selectCanvasTeamSubmitsByTeam = createSelector(
  canvasTeamSubmits,
  team,
  (canvasTeamSubmits, team) =>
    _.filter(canvasTeamSubmits, canvasTeamSubmit => {
      return canvasTeamSubmit.team_id === team.id;
    })
);

export const selectTeamsByCanvas = createSelector(
  teams,
  teamMembers,
  users,
  selectCanvasTeamSubmitsByCanvas,
  (teams, teamMembers, users, canvasTeamSubmits) => {
    return _.filter(teams, team => {
      let members = _.filter(teamMembers, teamMember => {
        return teamMember.team_id === team.id;
      });

      let tmpMembers = _.map(members, member => {
        let found = _.find(users, { id: member.user_id });
        return !_.isNil(found) ? found : {};
      });

      team.members = tmpMembers;
      let foundTeam = _.find(canvasTeamSubmits, { team_id: team.id });
      team.status = foundTeam
        ? foundTeam.status
          ? foundTeam.status
          : STATUS_DEFAULT
        : STATUS_DEFAULT;

      return foundTeam ? true : false;
    });
  }
);

export const selectCanvasesByTeam = createSelector(
  selectCanvasTeamSubmitsByTeam,
  canvases,
  (canvasTeamSubmits, canvases) => {
    return _.map(canvasTeamSubmits, canvasTeamSubmit => {
      let canvas = _.find(canvases, { id: canvasTeamSubmit.canvas_id });
      return {
        ...canvasTeamSubmit,
        name: canvas.en_name,
        created: formatDateFirestore(canvasTeamSubmit.created),
        status: canvasTeamSubmit.status ? canvasTeamSubmit.status : 0
      };
    });
  }
);

export const selectTeamsOptions = createSelector(
  teams,
  teams => {
    let tm = [{ id: "", name: "Please choose team" }];
    return tm.concat(teams);
  }
);

// Libs
import React from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
// import { Button } from "antd";

// Component
import Title from "@/components/title";
// import { CLASS_PROGRESS, CLASS_CANVAS, CLASS_SCORE } from "@/constants/routes";

function ClassBox({ title, code }) {
  return (
    <div className="p-4 bg-white border-1 border-primary box-shadow border-radius-16 d-flex align-items-center height-250">
      <div className="flex-auto">
        <Title title={title} className="mb-4 text-primary" />
        <Title tag="h6" title={code} className="mb-4 text-muted" />
        {/* <div className="d-flex align-items-center justify-content-between">
          <Button ghost type="primary" className="px-4 border-radius-8">
            <Link to={`${CLASS_PROGRESS}?class_id=${id}`}>Progress</Link>
          </Button>
          <Button ghost type="primary" className="px-4 border-radius-8">
            <Link to={`${CLASS_CANVAS}?class_id=${id}`}>Canvases</Link>
          </Button>
        </div>
        <Button ghost type="primary" className="mt-4 px-4 border-radius-8">
          <Link to={`${CLASS_SCORE}?class_id=${id}`}>Score</Link>
        </Button> */}
      </div>
    </div>
  );
}

ClassBox.propTypes = {
  title: PropTypes.string,
  code: PropTypes.string,
  id: PropTypes.string
};

export default ClassBox;

import { createSelector } from "reselect";
import _ from "lodash";
const schools = state => state.school.schools;
const teacher = state => state.teacher.teacher;

export const selectSchoolsByTeacher = createSelector(
  schools,
  teacher,
  (schools, teacher) => {
    // console.log({ schools, teacher });
    return _.filter(schools, item => {
      if (teacher.school_ids)
        return teacher.school_ids.includes(item.id);
      return false;
    });
  }
);

import {
  TEACHER_CLASS_SET_TEACHER_CLASSES,
  TEACHER_CLASS_GET_TEACHER_CLASSES,
  TEACHER_CLASS_SET_TEACHER_CLASSES_SUCCESS,
  TEACHER_CLASS_SET_TEACHER_CLASSES_REQUEST,
  TEACHER_CLASS_SET_TEACHER_CLASSES_ERROR
} from "./actionType";
import { firestoreRef } from "@/constants/firebase";
import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";

export const setTeacherClasses = teacherClasses => ({
  type: TEACHER_CLASS_SET_TEACHER_CLASSES,
  payload: { teacherClasses }
});

export const getTeacherClasses = () => ({
  type: TEACHER_CLASS_GET_TEACHER_CLASSES
});

export const setTeacherClassesSuccess = () => ({
  type: TEACHER_CLASS_SET_TEACHER_CLASSES_SUCCESS
});

export const setTeacherClassesRequest = () => ({
  type: TEACHER_CLASS_SET_TEACHER_CLASSES_REQUEST
});

export const setTeacherClassesError = message => ({
  type: TEACHER_CLASS_SET_TEACHER_CLASSES_ERROR,
  payload: { message }
});

const getTeacherClassesAsync = async obj => {
  let { user_id } = obj;
  return await firestoreRef
    .collection("teacher_classes")
    .where("teacher_id", "==", user_id)
    .get()
    .then(snapshots => {
      let data = [];
      snapshots.forEach(doc => {
        data.push({
          ...doc.data(),
          id: doc.id
        });
      });
      return data;
    });
};

function* fetchTeacherClasses() {
  try {
    const { user_id } = yield select(state => state.auth);
    yield put(setTeacherClassesRequest());
    const teacherClasses = yield call(getTeacherClassesAsync, { user_id });
    yield put(setTeacherClasses(teacherClasses));
    yield put(setTeacherClassesSuccess());
  } catch (error) {
    yield put(setTeacherClassesError(error.message));
  }
}

export function* watchGetTeacherClasses() {
  yield takeEvery(TEACHER_CLASS_GET_TEACHER_CLASSES, fetchTeacherClasses);
}

export default function* rootSaga() {
  yield all([fork(watchGetTeacherClasses)]);
}

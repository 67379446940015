import { createSelector } from "reselect";
import _ from "lodash";
const users = state => state.user.users;
const schools = state => state.school.schools;
const classes = state => state.class.classes;
const sessionLogs = state => state.sessionLog.sessionLogs;
const sessions = state => state.session.sessions;
const quizResultLogs = state => state.quizResultLog.quizResultLogs;
const team = state => state.team.team;

const currentLessonPercentage = (
  user,
  sessions,
  sessionLogs,
  quizResultLogs
) => {
  let arrange = [];
  let tmp = {};

  _.forEach(sessionLogs, sessionLog => {
    if (user.id === sessionLog.user_id) {
      _.forEach(quizResultLogs, quizResultLog => {
        if (
          quizResultLog.session_id === sessionLog.session_id &&
          user.id === quizResultLog.user_id
        ) {
          let result = Object.values(quizResultLog.result);
          result = _.map(result, re => {
            return re.percentage;
          });
          tmp[sessionLog.session_id] = result;
        }
      });
    }
  });

  _.forEach(sessions, session => {
    if (tmp[session.id]) {
      arrange.push(tmp[session.id]);
    } else arrange.push([]);
  });

  return arrange;
};

const currentLesson = (sessionLogs, user) => {
  let num = 0;
  _.forEach(sessionLogs, item => {
    num += item.user_id === user.id ? 1 : 0;
  });

  return num;
};

export const selectUsersProgress = createSelector(
  users,
  sessionLogs,
  team,
  (users, sessionLogs, team) =>
    _.filter(users, user => {
      user.currentLesson = currentLesson(sessionLogs, user);
      user.currentLessonPercentage = 99;
      if (team.id) if (user.team_id !== team.id) return false;
      return true;
    })
);

export const selectUsersScore = createSelector(
  users,
  sessions,
  sessionLogs,
  quizResultLogs,
  team,
  schools,
  classes,
  (users, sessions, sessionLogs, quizResultLogs, team, schools, classes) => {
    return _.filter(users, user => {
      user.currentLesson = currentLesson(sessionLogs, user);
      let foundSchool = _.find(schools, { id: user.school });
      user.schoolData = foundSchool ? foundSchool : {};
      let foundClass = _.find(classes, { id: user.class_id });
      user.classData = foundClass ? foundClass : {};
      user.currentLessonPercentage = currentLessonPercentage(
        user,
        sessions,
        sessionLogs,
        quizResultLogs
      );
      if (team.id) if (user.team_id !== team.id) return false;
      return true;
    });
  }
);

export const selectUsersChecked = createSelector(users, users => {
  return _.map(users, user => {
    return {
      ...user,
      is_remove: user.is_remove ? true : false,
      has_change: user.has_change ? true : false
    };
  });
});

/* eslint-disable indent */
import {
  TEACHER_SET_REQUEST,
  TEACHER_SET_SUCCESS,
  TEACHER_SET_ERROR,
  TEACHER_SET_TEACHERS,
  TEACHER_SET_TEACHER
} from "../actions/actionType";

const INIT_STATE = {
  teachers: [],
  teacher: {},
  loading: false,
  error: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TEACHER_SET_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case TEACHER_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        user: ""
      };
    case TEACHER_SET_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case TEACHER_SET_TEACHERS:
      return { ...state, teachers: action.payload.teachers };
    case TEACHER_SET_TEACHER:
      return { ...state, teacher: action.payload.teacher };
    default:
      return { ...state };
  }
};

import {
  CLASS_GET_CLASSES,
  CLASS_GET_CLASSES_SUCCESS,
  CLASS_GET_CLASSES_ERROR,
  CLASS_SET_CLASSES,
  CLASS_SET_CLASS,
  CLASS_SET_CLASS_BY_ID
} from "./actionType";
import { firestoreRef } from "@/constants/firebase";
import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";

export const setClasses = classes => ({
  type: CLASS_SET_CLASSES,
  payload: { classes }
});

export const setClass = cls => ({
  type: CLASS_SET_CLASS,
  payload: { cls }
});

export const setClassById = classId => ({
  type: CLASS_SET_CLASS_BY_ID,
  payload: { classId }
});

export const getClasses = () => ({
  type: CLASS_GET_CLASSES
});

export const setClassesSuccess = () => ({
  type: CLASS_GET_CLASSES_SUCCESS
});

export const setClassesRequest = () => ({
  type: CLASS_GET_CLASSES_SUCCESS
});

export const setClassesError = message => ({
  type: CLASS_GET_CLASSES_ERROR,
  payload: { message }
});

const getClassesAsync = async obj => {
  const { teacher } = obj;
  return await firestoreRef
    .collection("classes")
    .where("school_id", "in", teacher.school_ids)
    .get()
    .then(snapshots => {
      let data = [];
      snapshots.forEach(doc => {
        data.push({
          ...doc.data(),
          id: doc.id
        });
      });
      return data;
    });
};

function* fetchClasses() {
  try {
    const { teacher } = yield select(state => state.teacher);
    yield put(setClassesRequest());
    const classes = yield call(getClassesAsync, { teacher });
    yield put(setClasses(classes));
    yield put(setClassesSuccess());
  } catch (error) {
    yield put(setClassesError(error.message));
  }
}

export function* watchGetClasses() {
  yield takeEvery(CLASS_GET_CLASSES, fetchClasses);
}

export default function* rootSaga() {
  yield all([fork(watchGetClasses)]);
}

// export const STATUS_LEADER_DEFAULT = 0;
// export const STATUS_LEADER_DOWNGRADE_TO_TEAM_MEMBER = 1;
// export const STATUS_LEADER_UPGRADE_TO_LEADER = 2;

// export const STATUS_CHECK_LEADER_DEFAULT = 0;
// export const STATUS_CHECK_LEADER_DUPLICATE = 1;
// export const STATUS_CHECK_LEADER_NO_HAVE = 2;

export const STATUS_DEFAULT = 0;
export const STATUS_PROGRESSING = 1;
export const STATUS_COMPLETED = 2;

// canvas
export const TEAM_CANVAS = "team-canvas";
export const CUSTOMER_DISCOVERY = "customer-discovery";
export const COMPETITION_MATRIX = "competition-matrix";
export const IDEA_GENERATION_CANVAS = "idea-generation-canvas";
export const BUSINESS_MODEL_CANVAS = "business-model-canvas";
export const PROTOTYPE_CANVAS = "prototype-canvas";
export const NEGOTIATION_CANVAS = "negotiation-canvas";
export const PEER_FEEDBACK_CANVAS = "peer-feedback-canvas";
export const REFLECTION_CANVAS = "reflection-canvas";

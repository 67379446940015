/* eslint-disable indent */
import {
  TEACHER_CLASS_SET_TEACHER_CLASSES_REQUEST,
  TEACHER_CLASS_SET_TEACHER_CLASSES_SUCCESS,
  TEACHER_CLASS_SET_TEACHER_CLASSES_ERROR,
  TEACHER_CLASS_SET_TEACHER_CLASSES,
  TEACHER_CLASS_SET_TEACHER_CLASS
} from "../actions/actionType";

const INIT_STATE = {
  teacherClasses: [],
  teacherClass: {},
  loading: false,
  error: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TEACHER_CLASS_SET_TEACHER_CLASSES_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case TEACHER_CLASS_SET_TEACHER_CLASSES_SUCCESS:
      return {
        ...state,
        loading: false,
        user: ""
      };
    case TEACHER_CLASS_SET_TEACHER_CLASSES_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case TEACHER_CLASS_SET_TEACHER_CLASSES:
      return { ...state, teacherClasses: action.payload.teacherClasses };
    case TEACHER_CLASS_SET_TEACHER_CLASS:
      return { ...state, teacherClass: action.payload.teacherClass };
    default:
      return { ...state };
  }
};

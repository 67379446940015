// Libs
import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

// Components
import Public from "./public";
import Private from "./private";
import NotFound from "@/components/not-found";
import PrivateRoute from "@/components/private-route";

class RouteComponent extends Component {

  render() {
    return (
      <Switch>
        <PrivateRoute
          component={Private}
          authenticationComponent={Public}
        />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default RouteComponent;
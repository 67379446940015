import {
  CANVAS_TEAM_GET_CANVAS_TEAMS,
  CANVAS_TEAM_SET_CANVAS_TEAMS,
  CANVAS_TEAM_SET_CANVAS_TEAMS_SUCCESS,
  CANVAS_TEAM_SET_CANVAS_TEAMS_REQUEST,
  CANVAS_TEAM_SET_CANVAS_TEAMS_ERROR
} from "./actionType";
import { firestoreRef } from "@/constants/firebase";
import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";

export const setCanvasTeams = canvasTeams => ({
  type: CANVAS_TEAM_SET_CANVAS_TEAMS,
  payload: { canvasTeams }
});

export const getCanvasTeams = () => ({
  type: CANVAS_TEAM_GET_CANVAS_TEAMS
});

export const setCanvasTeamsSuccess = () => ({
  type: CANVAS_TEAM_SET_CANVAS_TEAMS_SUCCESS
});

export const setCanvasTeamsRequest = () => ({
  type: CANVAS_TEAM_SET_CANVAS_TEAMS_REQUEST
});

export const setCanvasTeamsError = message => ({
  type: CANVAS_TEAM_SET_CANVAS_TEAMS_ERROR,
  payload: { message }
});

const getCanvasTeamsAsync = async obj => {
  const { team, canvas } = obj;

  return await firestoreRef
    .collection("canvas_teams")
    .where("team_id", "==", team.id)
    .where("canvas_id", "==", canvas.id)
    .get()
    .then(snapshots => {
      let data = [];
      snapshots.forEach(doc => {
        data.push({
          ...doc.data(),
          id: doc.id
        });
      });
      return data;
    });
};

function* fetchCanvasTeams() {
  try {
    const { team } = yield select(state => state.team);
    const { canvas } = yield select(state => state.canvas);
    yield put(setCanvasTeamsRequest());
    const teams = yield call(getCanvasTeamsAsync, { team, canvas });
    yield put(setCanvasTeams(teams));
    yield put(setCanvasTeamsSuccess());
  } catch (error) {
    yield put(setCanvasTeamsError(error.message));
  }
}

export function* watchGetCanvasTeams() {
  yield takeEvery(CANVAS_TEAM_GET_CANVAS_TEAMS, fetchCanvasTeams);
}

export default function* rootSaga() {
  yield all([fork(watchGetCanvasTeams)]);
}

/* eslint-disable indent */
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGOUT_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS
} from "../actions/actionType";

const INIT_STATE = {
  user_id: localStorage.getItem("user_id"),
  loading: false,
  error: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: "" };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user_id: action.payload.uid,
        error: ""
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: "",
        error: action.payload.message
      };
    case REGISTER_USER:
      return { ...state, loading: true, error: "" };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user_id: action.payload.uid,
        error: ""
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      };
    case LOGOUT_USER:
      return { ...state, user_id: null, error: "" };
    default:
      return { ...state };
  }
};

import { createSelector } from "reselect";
import _ from "lodash";
import {
  STATUS_DEFAULT,
  STATUS_PROGRESSING,
  STATUS_COMPLETED
} from "@/constants/constants";
const canvasTeamSubmits = state => state.canvasTeamSubmit.canvasTeamSubmits;
const team = state => state.team.team;
// const teams = state => state.team.teams;
const canvas = state => state.canvas.canvas;
const canvases = state => state.canvas.canvases;

export const selectCanvasTeamSubmit = createSelector(
  canvasTeamSubmits,
  team,
  canvas,
  (canvasTeamSubmits, team, canvas) => {
    let found = _.find(canvasTeamSubmits, {
      team_id: team.id,
      canvas_id: canvas.id
    });

    return found ? found : {};
  }
);

export const selectCanvases = createSelector(
  canvasTeamSubmits,
  canvases,
  (canvasTeamSubmits, canvases) => {
    return _.filter(canvases, canvas => {
      if (canvas.isHide) return false;
      let isSubmit = false;
      let tmpCanvasTeamSubmits = _.filter(
        canvasTeamSubmits,
        canvasTeamSubmit => {
          const { canvas_id, status } = canvasTeamSubmit;
          if (canvas_id === canvas.id && _.isNil(status)) isSubmit = true;
          return canvas_id === canvas.id;
        }
      );

      canvas.status = STATUS_DEFAULT;
      if (tmpCanvasTeamSubmits.length && isSubmit)
        canvas.status = STATUS_PROGRESSING;
      else if (tmpCanvasTeamSubmits.length && !isSubmit)
        canvas.status = STATUS_COMPLETED;

      return true;
    });
  }
);

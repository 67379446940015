/* eslint-disable indent */
import {
  TEAM_MEMBER_SET_TEAMS_REQUEST,
  TEAM_MEMBER_TEAMS_SUCCESS,
  TEAM_MEMBER_TEAMS_ERROR,
  TEAM_MEMBER_SET_TEAMS,
  TEAM_MEMBER_SET_TEAM
} from "../actions/actionType";

const INIT_STATE = {
  teamMembers: [],
  teamMember: {},
  loading: false,
  error: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TEAM_MEMBER_SET_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case TEAM_MEMBER_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: ""
      };
    case TEAM_MEMBER_TEAMS_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case TEAM_MEMBER_SET_TEAMS:
      return { ...state, teamMembers: action.payload.teamMembers };
    case TEAM_MEMBER_SET_TEAM:
      return { ...state, teamMember: action.payload.teamMember };
    default:
      return { ...state };
  }
};

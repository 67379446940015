import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import km from "react-intl/locale-data/km";

import "./assets/styles/index.scss";

// Component
import App from "./views";

// Store
import configureStore from "./stores/configureStore";

// utils
import { setLocale } from "@/stores/actions/locale";

const store = configureStore();

addLocaleData(en);
addLocaleData(km);

if (localStorage.lang) {
  store.dispatch(setLocale(localStorage.lang));
}

ReactDOM.unstable_createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <App />
  </Provider>
);

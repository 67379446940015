import {
  TEACHER_SET_TEACHERS,
  TEACHER_GET_TEACHERS,
  TEACHER_GET_TEACHER,
  TEACHER_SET_SUCCESS,
  TEACHER_SET_REQUEST,
  TEACHER_SET_ERROR,
  TEACHER_SET_TEACHER,
  TEACHER_UPDATE_TEACHER
} from "./actionType";
import { firestoreRef } from "@/constants/firebase";
import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";

export const setTeachers = teachers => ({
  type: TEACHER_SET_TEACHERS,
  payload: { teachers }
});

export const setTeacher = teacher => ({
  type: TEACHER_SET_TEACHER,
  payload: { teacher }
});

export const getTeachers = () => ({
  type: TEACHER_GET_TEACHERS
});

export const getTeacher = teacherId => ({
  type: TEACHER_GET_TEACHER,
  payload: { teacherId }
});

export const updateTeacher = data => ({
  type: TEACHER_UPDATE_TEACHER,
  payload: { data }
});

export const setTeacherSuccess = () => ({
  type: TEACHER_SET_SUCCESS
});

export const setTeacherRequest = () => ({
  type: TEACHER_SET_REQUEST
});

export const setTeacherError = message => ({
  type: TEACHER_SET_ERROR,
  payload: { message }
});

const getTeachersAsync = async () =>
  await firestoreRef
    .collection("teachers")
    .get()
    .then(snapshots => {
      let data = [];
      snapshots.forEach(doc => {
        data.push({
          ...doc.data(),
          id: doc.id
        });
      });
      return data;
    });

const updateTeacherAsync = async ({ teacher, data }) =>
  await firestoreRef
    .collection("teachers")
    .doc(teacher.id)
    .update(data);

export const getTeacherAsync = async teacherId =>
  await firestoreRef
    .collection("teachers")
    .doc(teacherId)
    .get()
    .then(doc => {
      let data = { ...doc.data(), id: doc.id };
      return data;
    });

function* fetchTeachers() {
  try {
    yield put(setTeacherRequest());
    const teachers = yield call(getTeachersAsync);
    yield put(setTeachers(teachers));
    yield put(setTeacherSuccess());
  } catch (error) {
    yield put(setTeacherError(error.message));
  }
}

function* fetchTeacher({ payload }) {
  try {
    yield put(setTeacherRequest());
    const teacher = yield call(getTeacherAsync, payload.teacherId);
    yield put(setTeacher(teacher));
    yield put(setTeacherSuccess());
  } catch (error) {
    yield put(setTeacherError(error.message));
  }
}

function* setUpdateTeacher({ payload }) {
  try {
    const { data } = payload;
    const { teacher } = yield select(state => state.teacher);
    yield put(setTeacherRequest());
    yield call(updateTeacherAsync, { teacher, data });
    // yield put(setTeacher(teacher));
    yield put(setTeacherSuccess());
  } catch (error) {
    yield put(setTeacherError(error.message));
  }
}

export function* watchGetTeachers() {
  yield takeEvery(TEACHER_GET_TEACHERS, fetchTeachers);
}

export function* watchGetTeacher() {
  yield takeEvery(TEACHER_GET_TEACHER, fetchTeacher);
}

export function* watchUpdateTeacher() {
  yield takeEvery(TEACHER_UPDATE_TEACHER, setUpdateTeacher);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetTeachers),
    fork(watchGetTeacher),
    fork(watchUpdateTeacher)
  ]);
}

// Libs
import React, { Component, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Components
import NotFound from "@/components/not-found";
const SignIn = React.lazy(() => import("@/views/pages/log-in"));
const AdminSignIn = React.lazy(() =>
  import("@/views/pages/admin/pages/log-in")
);
// const SignUp = React.lazy(() => import("@/views/pages/create-account"));
const ForgetPassword = React.lazy(() =>
  import("@/views/pages/forget-password")
);
import Layout from "@/components/layout";
import { SING_IN, FORGET_PASSWORD } from "@/constants/routes";
import Download from "@/mytest";
class Public extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <Suspense fallback={<div className="loading loading-overlay" />}>
          <Switch>
            <Redirect exact from="/" to={SING_IN} />
            <Redirect exact from="/admin" to="/admin/sign-in" />
            <Route path="/admin/sign-in" component={AdminSignIn} />
            <Route path={SING_IN} component={SignIn} />
            {/* <Route path="/sign-up" component={SignUp} /> */}
            <Route path={FORGET_PASSWORD} component={ForgetPassword} />
            <Route path="/download" component={Download} />

            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Layout>
    );
  }
}

export default Public;

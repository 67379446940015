/* eslint-disable indent */
import _ from "lodash";
import {
  CLASS_GET_CLASSES_REQUEST,
  CLASS_GET_CLASSES_SUCCESS,
  CLASS_GET_CLASSES_ERROR,
  CLASS_SET_CLASSES,
  CLASS_SET_CLASS,
  CLASS_SET_CLASS_BY_ID
} from "../actions/actionType";

const INIT_STATE = {
  classes: [],
  cls: {},
  loading: false,
  error: ""
};

const findById = (state, action) => {
  let found = _.find(state.classes, { id: action.payload.classId });
  return { ...state, cls: found ? found : {} };
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLASS_GET_CLASSES_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case CLASS_GET_CLASSES_SUCCESS:
      return {
        ...state,
        loading: false,
        user: ""
      };
    case CLASS_GET_CLASSES_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case CLASS_SET_CLASSES:
      return { ...state, classes: action.payload.classes };
    case CLASS_SET_CLASS:
      return { ...state, cls: action.payload.cls };
    case CLASS_SET_CLASS_BY_ID:
      return findById(state, action);
    default:
      return { ...state };
  }
};

import { addLocaleData } from "react-intl";
import enLang from "./entries/en-US";
import kmLang from "./entries/km-KH";

const AppLocale = {
  en: enLang,
  km: kmLang,
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.km.data);

export default AppLocale;

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";

class PageNotFound extends Component {
  render() {
    const { status } = this.props;
    const Status = () => {
      return (
        <h1 className="mb-0 text-center text-primary display-2 font-weight-bold">
          {status}
        </h1>
      );
    };

    const Text = () => {
      return (
        <p className="text-center font-weight-light mb-0 lead">
          {status === 404
            ? "The page you were looking for doesn't exist"
            : "Something went wrong !"}
        </p>
      );
    };

    const Description = () => {
      return (
        <p className="text-center font-weight-light lead">
          Return to
          <span className="ml-1 font-weight-bold">
            <Link to="/">Home</Link>
          </span>{" "}
          or {status === 404 ? "explore" : "try again"}
        </p>
      );
    };

    const { fullHeight } = this.props;
    return (
      <Fragment>
        {fullHeight ? (
          <Container fluid>
            <Row className="h-100 p-3">
              <Col className="m-auto">
                <Status />
                <Text />
                <Description />
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row className="p-3">
              <Col lg="6" md="6" sm="12" xs="12" className="m-auto">
                <Status />
                <Text />
                <Description />
              </Col>
            </Row>
          </Container>
        )}
      </Fragment>
    );
  }
}

PageNotFound.propTypes = {
  name: PropTypes.string,
  status: PropTypes.number,
  fullHeight: PropTypes.bool
};

PageNotFound.defaultProps = {
  fullHeight: true,
  status: 404
};

export default PageNotFound;

// Libs
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Menu, Icon, Dropdown } from 'antd';
import { Nav, Navbar, NavItem, NavbarToggler, Collapse } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

// Component
import Title from '@/components/title';

// Utils
import './index.scss';
import { history } from '@/shared/utils/history';
import {
  logoutUser,
  setLocale,
  getTeacher,
  setClassById,
  // getTeacherClasses,
  getClasses,
  getCanvases,
  getTeams,
  setTeamById,
  setCanvasById,
  getCanvasTeams,
  getSchools,
  setSchools,
} from '@/stores/actions';
import IntlMessages from '@/helpers/IntlMessages';
import { getLocationParamSearch } from '@/helpers';
import { SING_IN } from '@/constants/routes';
// import Loading from "@/components/loading/loading";
// import { authRef } from "@/constants/firebase";
import { LOGO } from '@/constants/images';

const { Item, Divider } = Menu;

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const { user_id } = useSelector((state) => state.auth);
  const { teacher } = useSelector((state) => state.teacher);
  const { locale } = useSelector((state) => state.locale);
  const { classes } = useSelector((state) => state.class);
  const { teams } = useSelector((state) => state.team);
  const { canvases } = useSelector((state) => state.canvas);

  const dispatch = useDispatch();
  const { class_id, team_id, canvas_id } = getLocationParamSearch(history);

  useEffect(() => {
    dispatch(getCanvases());
  }, [dispatch]);

  useEffect(() => {
    if (user_id) {
      dispatch(getTeacher(user_id));
      // dispatch(getTeacherClasses());
      // if (!authRef.currentUser) {
      //   dispatch(logoutUser(history));
      // }
    }
  }, [dispatch, user_id]);

  useEffect(() => {
    dispatch(getClasses());
  }, [dispatch, teacher]);

  useEffect(() => {
    dispatch(getSchools());
    return () => {
      dispatch(setSchools([]));
    };
  }, [dispatch, teacher]);

  useEffect(() => {
    dispatch(setClassById(class_id));
  }, [classes, class_id, dispatch]);

  useEffect(() => {
    dispatch(getTeams(class_id));
  }, [class_id, dispatch]);

  useEffect(() => {
    dispatch(setTeamById(team_id));
  }, [teams, team_id, dispatch]);

  useEffect(() => {
    dispatch(setCanvasById(canvas_id));
  }, [canvases, canvas_id, dispatch]);

  useEffect(() => {
    dispatch(getCanvasTeams());
  }, [dispatch, canvases, teams, canvas_id, team_id]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onLogOut = () => {
    dispatch(logoutUser(history));
  };

  const renderDropdownMenu = () => {
    return (
      <Menu>
        <Title
          title='Smart Entrepreneurship'
          className='pt-2 px-3 text-white text-center font-18'
        />
        <Divider />
        <Item key='a-s'>
          <Link to='/account-setting'>Account Setting</Link>
        </Item>
        <Item key='logout' onClick={onLogOut}>
          Log out
        </Item>
      </Menu>
    );
  };

  return (
    <Navbar
      light
      color='white'
      expand='lg'
      className='border-primary border-bottom-2'
    >
      <Link to='/' className='navbar-brand custom-navbar-brand'>
        <img
          src={LOGO}
          className='navbar-brand-logo custom-navbar-brand'
          alt='logo'
        />
      </Link>
      <NavbarToggler
        onClick={toggle}
        className='d-flex d-lg-none text-primary border-primary'
      >
        <Icon type='menu' />
      </NavbarToggler>
      <Collapse isOpen={isOpen} navbar>
        <Nav navbar>
          {!user_id && (
            <Fragment>
              <NavItem>
                <Link to='/about-us'>
                  <IntlMessages id='about-us' />
                </Link>
              </NavItem>
              <NavItem>
                <Link to='/contact-us'>
                  <IntlMessages id='contact-us' />
                </Link>
              </NavItem>
              {/* <NavItem>
                <Link to="sign-up">
                  <IntlMessages id="sign-up" />
                </Link>
              </NavItem> */}
              <NavItem>
                <Link to={SING_IN}>
                  <IntlMessages id='sign-in' />
                </Link>
              </NavItem>
            </Fragment>
          )}
          <NavItem key='language'>
            <a
              onClick={() => dispatch(setLocale('en'))}
              className={`${locale == 'en' && 'active'} pr-2`}
            >
              EN
            </a>
            |
            <a
              onClick={() => dispatch(setLocale('km'))}
              className={`${locale == 'km' && 'active'} pl-2`}
            >
              ខ្មែរ
            </a>
          </NavItem>
          {user_id && (
            <Fragment>
              <NavItem key='userName' className='d-flex p-0 pl-3'>
                <Link
                  to='/account-setting'
                  className='py-2 px-3 rounded text-white bg-gradient-primary-left-right'
                >
                  {teacher.name}
                </Link>
                <div className='ml-2 d-flex font-20'>
                  <Dropdown
                    trigger={['click']}
                    overlay={renderDropdownMenu()}
                    overlayClassName='header-menu-dropdown'
                  >
                    <Icon
                      type='more'
                      className='text-muted p-2 rounded-circle'
                    />
                  </Dropdown>
                </div>
              </NavItem>
            </Fragment>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );
}

Header.propTypes = {
  setLocale: PropTypes.func,
  lang: PropTypes.string,
  isAuthorize: PropTypes.bool,
};

export default Header;

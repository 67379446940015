// Libs
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import Header from './header';
import Body from './body';
import { authRef } from '@/constants/firebase';
import { SING_IN } from '@/constants/routes';
import { history } from '@/shared/utils/history';

const Layout = ({ children, ...props }) => {
  // render() {
  //   const { children, ...props } = this.props;

  useEffect(() => {
    authRef.onAuthStateChanged((firebaseUser) => {
      if (!firebaseUser) {
        history.push(SING_IN);
      }
    });
  }, []);

  return (
    <Fragment>
      <Header {...props} />
      <Body>{children}</Body>
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;

import { SET_LOCALE } from "./actionType";

export const localeSet = (locale) => ({
  type: SET_LOCALE,
  locale,
});

export const setLocale = (locale) => (dispatch) => {
  localStorage.lang = locale;
  dispatch(localeSet(locale));
};
// Libs
import React, { Component, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Components
import Layout from "@/components/layout";
import Loading from "@/components/loading/loading";
import UnAuthorized from "@/components/UnAuthorized";
import { CLASS, ACCOUNT_SETTING, SCHOOL } from "@/constants/routes";
import SchoolList from "@/views/pages/class-mangement/pages/school";
import Excel from "./Excel";
const AccountSetting = React.lazy(() =>
  import("@/views/pages/account-setting")
);
const AdminDashboard = React.lazy(() => import("@/views/pages/admin"));
const NotFound = React.lazy(() => import("@/components/not-found"));
const Class = React.lazy(() => import("@/views/pages/class-mangement"));

class Private extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Redirect exact from="/" to={SCHOOL} />
            <Redirect exact from="/admin" to="/admin/dashboard" />
            <Route path="/admin/dashboard" component={AdminDashboard} />
            <Route path={CLASS} component={Class} />
            <Route path={SCHOOL} component={SchoolList} />
            <Route path={ACCOUNT_SETTING} component={AccountSetting} />
            <Route path="/401" component={UnAuthorized} />
            <Route path="/404" component={NotFound} />

            <Route path="/excel" component={Excel} />

            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Layout>
    );
  }
}

export default Private;

/* eslint-disable indent */
import { SET_LOCALE } from "../actions/actionType";

const initialState = {
  locale: "en"
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_LOCALE:
      return {
        locale: action.locale
      };
  }
  return state;
}

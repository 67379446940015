

module.exports = {

  "about-us": "About Us",
  "contact-us": "Contact Us",
  "copyright": "Gogo React © 2018 All Rights Reserved.",
  "login": "Login",
  "sign-up": "Sign Up",
  "sign-in": "Sign In",
};

import {
  TEAM_MEMBER_SET_TEAMS,
  TEAM_MEMBER_GET_TEAMS,
  TEAM_MEMBER_TEAMS_SUCCESS,
  TEAM_MEMBER_SET_TEAMS_REQUEST,
  TEAM_MEMBER_TEAMS_ERROR
} from "./actionType";
import { firestoreRef } from "@/constants/firebase";
import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";

export const setTeamMembers = teamMembers => ({
  type: TEAM_MEMBER_SET_TEAMS,
  payload: { teamMembers }
});

export const getTeamMembers = () => ({
  type: TEAM_MEMBER_GET_TEAMS
});

export const setTeamMembersSuccess = () => ({
  type: TEAM_MEMBER_TEAMS_SUCCESS
});

export const setTeamMembersRequest = () => ({
  type: TEAM_MEMBER_SET_TEAMS_REQUEST
});

export const setTeamMembersError = message => ({
  type: TEAM_MEMBER_TEAMS_ERROR,
  payload: { message }
});

const getTeamMembersAsync = async obj => {
  const { cls } = obj;
  return await firestoreRef
    .collection("team_members")
    .where("class_id", "==", cls.id)
    .get()
    .then(snapshots => {
      let data = [];
      snapshots.forEach(doc => {
        data.push({
          ...doc.data(),
          id: doc.id
        });
      });
      return data;
    });
};

function* fetchTeamMembers() {
  try {
    const { cls } = yield select(state => state.class);
    yield put(setTeamMembersRequest());
    const classes = yield call(getTeamMembersAsync, { cls });
    yield put(setTeamMembers(classes));
    yield put(setTeamMembersSuccess());
  } catch (error) {
    yield put(setTeamMembersError(error.message));
  }
}

export function* watchGetTeamMembers() {
  yield takeEvery(TEAM_MEMBER_GET_TEAMS, fetchTeamMembers);
}

export default function* rootSaga() {
  yield all([fork(watchGetTeamMembers)]);
}

/* eslint-disable indent */
import {
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_REQUEST,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_SUCCESS,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_ERROR,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAM
} from "../actions/actionType";

const INIT_STATE = {
  canvasTeamSubmits: [],
  canvasTeamSubmit: {},
  loading: false,
  error: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: ""
      };
    case CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS:
      return { ...state, canvasTeamSubmits: action.payload.canvasTeamSubmits };
    case CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAM:
      return { ...state, canvasTeamSubmit: action.payload.canvasTeamSubmit };
    default:
      return { ...state };
  }
};

import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./auth";
import classReducer from "./class";
import canvasReducer from "./canvas";
import canvasTeamReducer from "./canvasTeam";
import canvasTeamSubmitReducer from "./canvasTeamSubmit";
import localeReducer from "./locale";
import quizResultLogReducer from "./quizResultLog";
import sessionReducer from "./session";
import sessionLogReducer from "./sessionLog";
import schoolReducer from "./school";
import teamReducer from "./team";
import teamMemberReducer from "./teamMember";
import teacherClassReducer from "./teacherClass";
import teacherReducer from "./teacher";
import userReducer from "./user";
import { firebaseReducer } from "react-redux-firebase";

export default combineReducers({
  auth: authReducer,
  class: classReducer,
  canvas: canvasReducer,
  canvasTeam: canvasTeamReducer,
  canvasTeamSubmit: canvasTeamSubmitReducer,
  form: formReducer,
  firebase: firebaseReducer,
  locale: localeReducer,
  quizResultLog: quizResultLogReducer,
  session: sessionReducer,
  sessionLog: sessionLogReducer,
  school: schoolReducer,
  team: teamReducer,
  teamMember: teamMemberReducer,
  teacherClass: teacherClassReducer,
  teacher: teacherReducer,
  user: userReducer
});

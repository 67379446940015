/* eslint-disable indent */
import _ from "lodash";
import {
  TEAM_SET_TEAMS_REQUEST,
  TEAM_SET_TEAMS_SUCCESS,
  TEAM_SET_TEAMS_ERROR,
  TEAM_SET_TEAM,
  TEAM_SET_TEAMS,
  TEAM_SET_TEAM_BY_ID
} from "../actions/actionType";

const INIT_STATE = {
  teams: [],
  team: {},
  loading: false,
  error: ""
};

const findById = (state, action) => {
  let found = _.find(state.teams, { id: action.payload.teamId });
  return { ...state, team: found ? found : {} };
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TEAM_SET_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case TEAM_SET_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: ""
      };
    case TEAM_SET_TEAMS_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case TEAM_SET_TEAMS:
      return { ...state, teams: action.payload.teams };
    case TEAM_SET_TEAM:
      return { ...state, team: action.payload.team };
    case TEAM_SET_TEAM_BY_ID:
      return findById(state, action);
    default:
      return { ...state };
  }
};
